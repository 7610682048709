import React from 'react';
import NavBar from './../navbar/navbar'
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Navbar from './../navbar/navbar';

const useStyles = makeStyles((theme) => ({
    mobile:{
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        height: '100vh'
    },
    desktop: {
        width: '1152px',
        // width: '576px'
        minHeight: '576px',
        margin: '48px auto auto auto',
    }
}))

const Responsive = Component => function Responsive(props){
    const classes = useStyles();
    const isDesktop = useMediaQuery('(min-width: 1152px)')

    return (
        <div className={isDesktop ? classes.desktop : classes.mobile}>
            <Navbar isDesktop={isDesktop} />
            <Component isDesktop={isDesktop} {...props} />
        </div>
    )
}

export default Responsive