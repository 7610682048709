import React from 'react';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Responsive from './../mediaQuery/responsive'

const mobileStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        flex: '1 0 auto',
    },
    title: {
        margin: '30px auto 25px auto',
        padding: '0',
        fontFamily: 'Roboto',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '22px',
        color: '#5B6268'
    },
    message: {
        margin: '0 16px 35px 16px',
        padding: '0',
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#5B6268',
        textAlign: 'center'
    },
    imageExpired: {
        margin: '0 auto auto auto'
    }
}));

const desktopStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        flex: '1 0 auto',
    },
    title: {
        margin: '62px auto 50px auto',
        padding: '0',
        fontFamily: 'Roboto',
        fontWeight: '500',  
        fontSize: '40px',
        lineHeight: '44px',
        color: '#5B6268'
    },
    message: {
        margin: '0 32px 87px 32px',
        padding: '0',
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '32px',
        lineHeight: '44px',
        color: '#5B6268',
        textAlign: 'center'
    },
    imageExpired: {
        width: '222px',
        height: '324px',
        margin: '0 auto 501px auto'
    }
}));

const OfferExpired = props => {
    const classes = props.isDesktop ? desktopStyles() : mobileStyles();

    return (
        <div className={classes.root}>
            <p className={classes.title}>Tu oferta ya está vencida</p>
            <p className={classes.message}>
                ¡Sigue usando Sr.Pago y pronto podrás volver a recibir una oferta con Bontu!
            </p>

            <svg className={classes.imageExpired} width="111" height="162" viewBox="0 0 111 162" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M65.3664 151.743C90.3976 146.193 106.203 121.348 100.668 96.2491C95.1328 71.1503 70.3541 55.3028 45.323 60.8526C20.2918 66.4024 4.48689 91.2479 10.0217 116.347C15.5566 141.445 40.3353 157.293 65.3664 151.743Z" fill="#242C3C" />
                <path d="M55.3455 145.511C33.7432 145.511 16.2383 127.954 16.2383 106.298C16.2383 84.6434 33.7487 67.0857 55.3455 67.0857C76.9423 67.0857 94.4527 84.6434 94.4527 106.298C94.4527 127.954 76.9478 145.511 55.3455 145.511Z" fill="white" />
                <path d="M93.0824 108.27C92.856 86.897 75.5775 69.6217 54.2402 69.4833V108.27H93.0824Z" fill="#E4472E" fill-opacity="0.2" />
                <path d="M56.5431 69.7715H54.1465V77.6727H56.5431V69.7715Z" fill="#5B6268" />
                <path d="M56.5431 135.057H54.1465V142.959H56.5431V135.057Z" fill="#5B6268" />
                <path d="M91.8409 105.164H83.9609V107.572H91.8409V105.164Z" fill="#5B6268" />
                <path d="M26.7351 105.164H18.8496V107.572H26.7351V105.164Z" fill="#5B6268" />
                <path d="M84.8151 120.01L83.9766 121.732L87.5985 123.506L88.4371 121.784L84.8151 120.01Z" fill="#5B6268" />
                <path d="M22.8092 89.7616L22.0215 91.3784L25.5591 93.1111L26.3468 91.4943L22.8092 89.7616Z" fill="#5B6268" />
                <path d="M72.3654 134.249L70.7129 135.211L72.7365 138.707L74.389 137.745L72.3654 134.249Z" fill="#5B6268" />
                <path d="M37.6342 74.5139L36.082 75.4171L38.0609 78.8364L39.6131 77.9332L37.6342 74.5139Z" fill="#5B6268" />
                <path d="M39.8068 135.243L37.998 138.855L39.7056 139.715L41.5143 136.103L39.8068 135.243Z" fill="#5B6268" />
                <path d="M70.766 73.4503L68.9961 76.983L70.5949 77.7883L72.3648 74.2556L70.766 73.4503Z" fill="#5B6268" />
                <path d="M26.0605 120.907L22.4941 122.792L23.3849 124.487L26.9512 122.602L26.0605 120.907Z" fill="#5B6268" />
                <path d="M87.0823 88.6837L83.5938 90.526L84.4301 92.1182L87.9186 90.2759L87.0823 88.6837Z" fill="#5B6268" />
                <path d="M55.605 109.222C54.7159 109.222 53.998 108.43 53.998 107.45C53.998 106.47 54.7159 105.678 55.605 105.678H81.7298C82.6189 105.678 83.3368 106.47 83.3368 107.45C83.3368 108.43 82.6189 109.222 81.7298 109.222H55.605Z" fill="#5B6268" />
                <path d="M56.576 106.221C57.073 106.713 56.9129 107.677 56.2226 108.374C55.5323 109.067 54.5715 109.222 54.08 108.729L39.5294 94.1389C39.0324 93.6461 39.1926 92.6826 39.8828 91.9905C40.5731 91.2984 41.5339 91.1378 42.0254 91.6362L56.576 106.221Z" fill="#5B6268" />
                <path d="M82.7793 61.583L77.8516 67.4838L83.292 72.0516L88.2197 66.1508L82.7793 61.583Z" fill="#242C3C" />
                <path d="M93.144 67.9C92.371 68.8247 90.996 68.9465 90.0738 68.1768L80.4709 60.115C79.5487 59.3399 79.4272 57.9611 80.2003 57.0365C80.9734 56.1118 82.3484 55.99 83.2761 56.7596L92.879 64.8214C93.7956 65.5966 93.9171 66.9753 93.144 67.9Z" fill="#242C3C" />
                <path d="M49.1147 54.1187L49.0781 62.4408L61.5083 62.4957L61.5448 54.1736L49.1147 54.1187Z" fill="#242C3C" />
                <path d="M51.564 54.1331L51.5273 62.4552L53.9736 62.466L54.0103 54.144L51.564 54.1331Z" fill="#003454" />
                <path d="M55.1082 54.1403L55.0742 62.4624L55.7866 62.4653L55.8206 54.1433L55.1082 54.1403Z" fill="#003454" />
                <path d="M70.1497 48.98C70.1387 52.2191 68.4103 54.8381 66.3009 54.8325L44.3617 54.7329C42.2468 54.7218 40.5515 52.0862 40.5626 48.8471C40.5791 45.6079 42.302 42.989 44.4114 43L66.3561 43.0997C68.4655 43.1052 70.1608 45.7408 70.1497 48.98Z" fill="#242C3C" />
                <path d="M45.703 51.6104C45.703 51.8319 45.5208 52.0146 45.2999 52.0146C45.0791 52.0146 44.9023 51.8319 44.9023 51.6104V46.2285C44.9023 46.007 45.0791 45.8298 45.2999 45.8298C45.5208 45.8298 45.703 46.007 45.703 46.2285V51.6104Z" fill="#003B4A" />
                <path d="M51.0155 51.6104C51.0155 51.8319 50.8388 52.0146 50.618 52.0146C50.3971 52.0146 50.2148 51.8319 50.2148 51.6104V46.2285C50.2148 46.007 50.3916 45.8298 50.618 45.8298C50.8388 45.8298 51.0155 46.007 51.0155 46.2285V51.6104Z" fill="#003B4A" />
                <path d="M56.3284 51.6104C56.3284 51.8319 56.1517 52.0146 55.9308 52.0146C55.7099 52.0146 55.5332 51.8319 55.5332 51.6104V46.2285C55.5332 46.007 55.7099 45.8298 55.9308 45.8298C56.1517 45.8298 56.3284 46.007 56.3284 46.2285V51.6104Z" fill="#003B4A" />
                <path d="M61.6409 51.6104C61.6409 51.8319 61.4642 52.0146 61.2433 52.0146C61.0224 52.0146 60.8457 51.8319 60.8457 51.6104V46.2285C60.8457 46.007 61.0224 45.8298 61.2433 45.8298C61.4642 45.8298 61.6409 46.007 61.6409 46.2285V51.6104Z" fill="#003B4A" />
                <path d="M66.953 51.6104C66.953 51.8319 66.7763 52.0146 66.5555 52.0146C66.3346 52.0146 66.1523 51.8319 66.1523 51.6104V46.2285C66.1523 46.007 66.3291 45.8298 66.5555 45.8298C66.7763 45.8298 66.953 46.007 66.953 46.2285V51.6104Z" fill="#003B4A" />
                <path d="M60.3478 107.567C60.3478 110.335 58.1113 112.578 55.3448 112.578C52.5838 112.578 50.3418 110.335 50.3418 107.567C50.3418 104.798 52.5782 102.556 55.3448 102.556C58.1113 102.556 60.3478 104.798 60.3478 107.567Z" fill="#5B6268" />
                <path d="M56.4111 106.825C55.7761 107.445 55.0527 107.733 54.7987 107.467C54.5502 107.207 54.8594 106.493 55.4945 105.873L74.286 87.6783C74.9266 87.0581 75.65 86.7702 75.904 87.036C76.158 87.2962 75.8433 88.016 75.2082 88.6306L56.4111 106.825Z" fill="#5B6268" />
                <path d="M58.9242 107.567C58.9242 109.549 57.3228 111.155 55.3459 111.155C53.369 111.155 51.7676 109.549 51.7676 107.567C51.7676 105.584 53.369 103.979 55.3459 103.979C57.3228 103.984 58.9242 105.584 58.9242 107.567Z" fill="#5B6268" />
                <path d="M55.8026 25.3345C54.8056 25.3345 53.998 24.525 53.998 23.5254V1.80913C53.998 0.809584 54.8056 0 55.8026 0C56.7997 0 57.6072 0.809584 57.6072 1.80913V23.5254C57.6072 24.525 56.7997 25.3345 55.8026 25.3345Z" fill="#5B6268" fill-opacity="0.3" />
                <path d="M64.4665 25.3344C64.2793 25.3344 64.0875 25.305 63.898 25.2417C62.9529 24.9273 62.4386 23.9029 62.7521 22.9554L67.0854 9.85732C67.3989 8.90979 68.4208 8.39419 69.3659 8.70853C70.3111 9.02286 70.8254 10.0473 70.5118 10.9948L66.1786 24.0929C65.9282 24.855 65.2244 25.3344 64.4665 25.3344Z" fill="#5B6268" fill-opacity="0.3" />
                <path d="M47.1387 25.3347C46.383 25.3347 45.6792 24.8553 45.4266 24.0977L41.0933 11.0675C40.7775 10.12 41.2895 9.09555 42.2347 8.77896C43.1821 8.46236 44.2017 8.9757 44.5175 9.92323L48.8507 22.9535C49.1666 23.901 48.6545 24.9254 47.7094 25.242C47.5199 25.3053 47.3259 25.3347 47.1387 25.3347Z" fill="#5B6268" fill-opacity="0.3" />
            </svg>
        </div >
    );
}

export default Responsive(OfferExpired);
