import React, { useState } from 'react';
import Typography from "@material-ui/core/Typography";
import Dialog from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/*------DOCUMENTACIÓN------*/
//  https://www.npmjs.com/package/react-pdf
//  Este componente está pensado para reducir el código necesario para implementar un visor de PDF.
//  Los props que acepta son:
//  open:           bool        (indica si se abrirá el visor o no)
//  onClose:        func        (callback para cerrar componente)
//  file:           string      (url, contenido en base64, uint8arr, y más)
//  title:          string      (título del visor)
//  scale:          float       (zoom del contenido)

const dialogTitleStyles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
const DialogTitle = withStyles(dialogTitleStyles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});

const PDFViewer = props => {
    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };
    return(        
        <Dialog className="pdfMaxWidth" open={props.open} onClose={props.onClose} >
            <DialogTitle onClose={props.onClose}>
                {props.title}
            </DialogTitle>
            <DialogContent dividers>     
                <Document
                renderMode="canvas"                    
                  file={props.file}
                  onLoadSuccess={onDocumentLoadSuccess} >
                    {   Array.apply(null, Array(numPages))
                        .map((x, i)=>i+1)
                        .map(page => <Page key={"pag_" + page} scale={props.scale} pageNumber={page}/>)
                    }
                </Document>
            </DialogContent>
        </Dialog>
    );

};

export default PDFViewer;