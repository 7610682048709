import './App.css';
import Form from './components/templates/form';
import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import ValidInfo from "./components/templates/validInfo";
import OfferExpired from "./components/templates/offerExpired";
import SecCredit from "./components/templates/2ndCredit";
import NotFound from "./components/templates/notFound";

const App = () => {
    return (
        <BrowserRouter>
            <Route exact path='/' component={Form} />
            <Route path='/validInfo' component={ValidInfo} />
            <Route path='/credit' component={SecCredit} />
            <Route path='/offerExpired' component={OfferExpired} />
            <Route path='/not-found' component={NotFound} />
        </BrowserRouter>
    )
}

export default App;