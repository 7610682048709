import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import 'fontsource-roboto';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import environment from "../../environment/environment";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { green } from '@material-ui/core/colors';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import Responsive from './../mediaQuery/responsive';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import LoadingModal from '../LoadingModal';
import axios from 'axios';
import PDFViewer from '../PDFViewer';
import Link from '@material-ui/core/Link';
const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const Form = props => {
    const classes = useStyles();
    let history = useHistory();
    const [isActive, setActiveState] = useState(false);
    const [circularActive, setDialogState] = useState(false);
    const [open, setOpen] = useState(false);
    const [messageAlert, setMessage] = useState('');
    const [severity, setSeverity] = useState('');
    const urlParams = new URLSearchParams(window.location.search);
    const [clickedPrivacy, setClickedPrivacy] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [bodyToSend, setBody] = useState({
        token: '',
        name: '',
        phone: '',
        clabe: '',
        email: ''
    });

    const [state, setState] = React.useState({
        checkedA: false,
        checkedB: false,
        checkedF: false,
        checkedG: false,
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        setActiveState(!isActive);
    };
    const toggleCheck = evt => {
        setClickedPrivacy(evt.target.checked);
    };
    useEffect(() => {

        let body = {
            token: urlParams.get('token') ? urlParams.get('token') : 'test',
            name: urlParams.get('full_name') ? urlParams.get('full_name') : 'test',
            phone: urlParams.get('telephone') ? urlParams.get('telephone') : 'test',
            clabe: urlParams.get('clabe') ? urlParams.get('clabe') : 'test',
            email: urlParams.get('email') ? urlParams.get('email') : 'test'
        };
        setBody(body);
        // axios({
        //     url: "https://srpago-assets.s3.amazonaws.com/aviso_de_privacidad.pdf", //your url
        //     method: 'GET',
        //     responseType: 'blob', // important
        //   }).then(response => {
        //     console.log("res", response);
        // });
    }, [props]);
    const Submit = () => {
        setOpen(false);
        setDialogState(!circularActive);


        axios.post(environment.bontu.domain + '/solicitudes/creditStatus', {
            token: bodyToSend.token,
            name: bodyToSend.name,
            phone: bodyToSend.phone,
            clabe: bodyToSend.clabe,
            email: bodyToSend.email
        })
            .then(function (response) {
                console.log(response);
                setSeverity('success');
                setMessage(response.message);
                setOpen(true);
                setDialogState(false);
                let status = response.data.action;
                history.push(status);
            })
            .catch(function (error) {
                if (error.response) {
                    //entra con status 409, 500
                    console.log(error.response)
                    setDialogState(false);
                    setSeverity('error')
                    setMessage(error.response.data);
                    setOpen(true);
                }
            });
    }

    return (
        <div>
            <LoadingModal open={isLoading} />
            <PDFViewer file="https://srpago-assets.s3.amazonaws.com/aviso_de_privacidad.pdf"
                open={openDialog} onClose={() => { setOpenDialog(false) }} title="Aviso de Privacidad" scale={1.8} />
            <Card className={classes.root}>
                <CardContent>

                    <form className={classes.root} noValidate autoComplete="off">
                        <div style={{ color: 'gray' }}>
                            <Typography variant="h5" gutterBottom style={{
                                color: '#5B6268',
                                fontFamily: 'Roboto',
                                fontSize: '20px',
                                lineHeight: '22px',
                                fontWeight: '500'
                            }}>
                                ¡Hola {bodyToSend.name}!
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                                Verifica la siguiente información y haz clic en continuar. Si los datos son incorrectos,
                                comunicate con servicio al cliente al xxxx.xxxx
                            </Typography>

                        </div>
                        <br />
                        <div>
                            <TextField
                                disabled={true}
                                id="outlined-required"
                                label="Nombre Completo"
                                variant="outlined"
                                value={bodyToSend.name}
                                style={{ width: '96%' }}
                            />
                            <TextField
                                disabled={true}
                                id="outlined-required"
                                label="Correo electrónico"
                                variant="outlined"
                                value={bodyToSend.email}
                                style={{ width: '96%' }}
                            />
                            <TextField
                                disabled={true}
                                id="outlined-required"
                                label="Cuenta CLABE"
                                variant="outlined"
                                value={bodyToSend.clabe}
                                style={{ width: '96%' }}
                            />
                            <TextField
                                disabled={true}
                                id="outlined-required"
                                label="Teléfono"
                                variant="outlined"
                                value={bodyToSend.phone}
                                style={{ width: '96%' }}
                            />

                            <FormGroup row>
                                <FormControlLabel
                                    control={<GreenCheckbox checked={state.checkedG} onChange={handleChange}
                                        name="checkedG" />}
                                    label="Acepto compartir mis datos con Bontu."
                                />
                            </FormGroup>
                            <FormGroup row>
                                <FormControlLabel
                                    control={<GreenCheckbox checked={clickedPrivacy} onChange={toggleCheck}
                                        name="checkedG" />}
                                    label={
                                        <span>He leído el <Link onClick={() => setOpenDialog(true)} >Aviso de Privacidad</Link>, y acepto el contenido del mismo</span>
                                    }
                                />
                            </FormGroup>


                            <div style={{ padding: '20px 20px 20px 0px' }}>
                                {(isActive && clickedPrivacy) ? <Button variant="contained"
                                    style={{
                                        background: 'rgba(0, 192, 106)',
                                        color: 'white',
                                        width: '102%'
                                    }}
                                    onClick={() => Submit()}>
                                    Continuar &nbsp; {circularActive ? <CircularProgress color="white" /> : null}
                                </Button> :
                                    <Button variant="contained"
                                        style={{
                                            background: 'lightgray',
                                            color: 'white',
                                            width: '102%'
                                        }}
                                        disabled={true}>
                                        Continuar

                                    </Button>
                                }
                            </div>

                        </div>

                    </form>
                </CardContent>
            </Card>

            <div><br />
                <Collapse in={open}>
                    <Alert severity={severity}>
                        {messageAlert}!
                    </Alert>
                </Collapse>
            </div>
        </div>
    );
}

export default Responsive(Form);
