const environment = {
	bontu: {
		domain: 'https://api.bontu-dev.io/qa',
		apiKey: 'PsRwYu6JVWa4Ms4RkHOecDao0erme6QaaQCk52u0'
	},
	production: true,
	hmr: false
};

export default environment;
