import React from 'react';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import WarningIcon from '@material-ui/icons/Warning';
import Responsive from './../mediaQuery/responsive'


const mobileStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        flex: '1 0 auto',
    },
    title: {
        margin: '30px auto 25px auto',
        padding: '0',
        fontFamily: 'Roboto',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '22px',
        color: '#5B6268'
    }
}));

const desktopStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        flex: '1 0 auto',
    },
    title: {
        margin: '62px auto 576px auto',
        padding: '0',
        fontFamily: 'Roboto',
        fontWeight: '500',  
        fontSize: '40px',
        lineHeight: '44px',
        color: '#5B6268'
    },
}));

const NotFound = props => {
    const classes = props.isDesktop ? desktopStyles() : mobileStyles();

    return (
        <div className={classes.root}>
            <p className={classes.title}>404 - Not Found <WarningIcon /></p>
        </div>
    );
}

export default Responsive(NotFound);
