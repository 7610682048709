import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Responsive from './../mediaQuery/responsive'

const mobileStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        flex: '1 0 auto',
    },
    title: {
        margin: '30px auto 25px auto',
        padding: '0',
        fontFamily: 'Roboto',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '22px',
        color: '#5B6268'
    },
    message: {
        margin: '0 16px 53px 16px',
        padding: '0',
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#5B6268',
        textAlign: 'center'
    },
    bontuHandImg: {
        margin: '0 auto auto auto'
    }
}));

const desktopStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        flex: '1 0 auto',
    },
    title: {
        margin: '62px auto 50px auto',
        padding: '0',
        fontFamily: 'Roboto',
        fontWeight: '500',  
        fontSize: '40px',
        lineHeight: '44px',
        color: '#5B6268'
    },
    message: {
        margin: '0 32px 106px 32px',
        padding: '0',
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontSize: '32px',
        lineHeight: '44px',
        color: '#5B6268',
        textAlign: 'center'
    },
    bontuHandImg: {
        width: '222px',
        height: '474px',
        margin: '0 auto 214px auto'
    }
}));

const SecCredit = props => {
    const classes = props.isDesktop ? desktopStyles() : mobileStyles();

    return (
        <div className={classes.root}>
            <p className={classes.title}>¡Gracias por usar Bontu!</p>
            <p className={classes.message}>
                Ya tenemos registrada tu información. Para continuar con tu proceso de solicitud de crédito,
                ingresa a la app
                de Bontu.
            </p>

            <svg className={classes.bontuHandImg} width="111" height="237" viewBox="0 0 111 237" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                    <path d="M55.9413 25.3345C54.9443 25.3345 54.1367 24.525 54.1367 23.5254V1.80913C54.1367 0.809584 54.9443 0 55.9413 0C56.9383 0 57.7459 0.809584 57.7459 1.80913V23.5254C57.7459 24.525 56.9383 25.3345 55.9413 25.3345Z" fill="#00C08B" />
                    <path d="M64.6052 25.3343C64.4179 25.3343 64.2262 25.3049 64.0367 25.2416C63.0916 24.9272 62.5772 23.9028 62.8908 22.9553L67.2241 9.8572C67.5376 8.90967 68.5595 8.39407 69.5046 8.70841C70.4498 9.02274 70.9641 10.0472 70.6505 10.9947L66.3173 24.0928C66.0669 24.8549 65.3631 25.3343 64.6052 25.3343Z" fill="#00C08B" />
                    <path d="M47.2773 25.3348C46.5217 25.3348 45.8179 24.8554 45.5652 24.0978L41.232 11.0676C40.9162 10.1201 41.4282 9.09568 42.3734 8.77908C43.3208 8.46248 44.3404 8.97582 44.6562 9.92335L48.9894 22.9536C49.3052 23.9011 48.7932 24.9255 47.848 25.2421C47.6585 25.3054 47.4645 25.3348 47.2773 25.3348Z" fill="#00C08B" />
                    <path d="M87.0012 93.9983L80.6426 97.4233L87.4755 110.173L93.8341 106.748L87.0012 93.9983Z" fill="#F2BB64" />
                    <path d="M90.4178 100.375C92.3036 103.896 90.9862 108.283 87.4763 110.174C83.9641 112.064 79.588 110.744 77.7022 107.223C75.8164 103.702 77.1338 99.3146 80.6437 97.4241C84.1536 95.5335 88.5297 96.8542 90.4178 100.375Z" fill="#F2BB64" />
                    <path d="M96.7772 96.9489C98.663 100.47 97.3456 104.857 93.8357 106.748C90.3235 108.638 85.9474 107.317 84.0616 103.796C82.1758 100.275 83.4932 95.8883 87.0031 93.9978C90.513 92.1072 94.8891 93.4279 96.7772 96.9489Z" fill="#F2BB64" />
                    <path d="M93.3606 90.5696L87.002 93.9946L93.8348 106.744L100.193 103.319L93.3606 90.5696Z" fill="#F6AEA6" />
                    <path d="M96.7772 96.9489C98.663 100.47 97.3456 104.857 93.8357 106.748C90.3235 108.638 85.9474 107.317 84.0616 103.796C82.1758 100.275 83.4932 95.8883 87.0031 93.9978C90.513 92.1072 94.8891 93.4279 96.7772 96.9489Z" fill="#F6AEA6" />
                    <path d="M103.137 93.5253C105.022 97.0463 103.705 101.433 100.195 103.324C96.6829 105.217 92.3068 103.894 90.421 100.375C88.5352 96.8541 89.8525 92.467 93.3624 90.5764C96.8724 88.6836 101.248 90.0043 103.137 93.5253Z" fill="#F6AEA6" />
                    <path d="M100.357 86.8018L93.998 90.2261L100.829 102.975L107.188 99.551L100.357 86.8018Z" fill="#F6AEA6" />
                    <path d="M103.781 93.1767C105.667 96.6977 104.349 101.085 100.84 102.975C97.3273 104.866 92.9512 103.545 91.0654 100.026C89.1796 96.5055 90.497 92.1183 94.0092 90.2278C97.5168 88.335 101.893 89.6557 103.781 93.1767Z" fill="#F6AEA6" />
                    <path d="M110.14 89.7531C112.026 93.2741 110.709 97.6613 107.199 99.5518C103.687 101.442 99.3106 100.122 97.4248 96.6007C95.539 93.0796 96.8564 88.6925 100.369 86.802C103.876 84.9114 108.252 86.2321 110.14 89.7531Z" fill="#F6AEA6" />
                    <path d="M93.8375 171.759C93.8375 173.749 92.2134 175.377 90.2283 175.377H21.6541C19.669 175.377 18.0449 173.749 18.0449 171.759V35.735C18.0449 33.7449 19.669 32.1167 21.6541 32.1167H90.2283C92.2134 32.1167 93.8375 33.7449 93.8375 35.735V171.759Z" fill="#242C3C" />
                    <path d="M90.2285 49.9141H21.6543V158.462H90.2285V49.9141Z" fill="white" />
                    <path d="M45.9958 101.464C44.3645 101.464 43.1339 101.044 42.3043 100.214C41.4746 99.3754 41.0645 98.117 41.0645 96.439C41.0645 94.6212 41.4746 93.3348 42.3043 92.5797C43.1339 91.8246 44.3645 91.4424 45.9958 91.4424C47.0678 91.4424 47.9721 91.6009 48.7085 91.9271C49.4543 92.2534 50.0136 92.7848 50.4051 93.5119C50.7873 94.239 50.9831 95.2178 50.9831 96.4297C50.9831 98.1076 50.5543 99.3661 49.6873 100.205C48.8297 101.053 47.5992 101.464 45.9958 101.464ZM45.9958 98.8534C46.3128 98.8534 46.6017 98.7695 46.8628 98.6017C47.1238 98.4339 47.3195 98.1729 47.4594 97.8186C47.5992 97.4644 47.6738 96.9983 47.6738 96.439C47.6738 95.7771 47.5992 95.2737 47.4594 94.9195C47.3195 94.5653 47.1144 94.3322 46.8628 94.211C46.6017 94.0898 46.3128 94.0246 45.9958 94.0246C45.6509 94.0246 45.3526 94.0898 45.1102 94.211C44.8678 94.3322 44.6814 94.5746 44.5602 94.9195C44.439 95.2737 44.3738 95.7771 44.3738 96.439C44.3738 97.3153 44.5136 97.9305 44.7839 98.3034C45.0729 98.667 45.4738 98.8534 45.9958 98.8534Z" fill="#003B4A" />
                    <path d="M52.6519 92.6265C52.8477 92.5146 53.1553 92.3934 53.5655 92.2629C53.9756 92.1324 54.4417 92.0112 54.9451 91.8993C55.4578 91.7875 55.9705 91.6943 56.4833 91.6197C56.996 91.5451 57.4621 91.5078 57.8816 91.5078C58.6926 91.5078 59.401 91.601 60.0163 91.7968C60.6316 91.9832 61.107 92.3188 61.4519 92.7943C61.7968 93.2697 61.9646 93.9315 61.9646 94.7892V101.184H58.6087V95.6095C58.6087 95.3671 58.5714 95.1527 58.5061 94.9756C58.4409 94.7985 58.3383 94.6587 58.2078 94.5561C58.0773 94.4536 57.9188 94.379 57.7324 94.3231C57.546 94.2765 57.3222 94.2485 57.0799 94.2485C56.8468 94.2485 56.6138 94.2858 56.39 94.351C56.1663 94.4163 56.0265 94.4815 55.9985 94.5468V101.193H52.6426V92.6265H52.6519Z" fill="#003B4A" />
                    <path d="M68.3958 101.65C67.128 101.65 66.2145 101.37 65.6458 100.82C65.0772 100.27 64.7882 99.4874 64.7882 98.4807V94.3044H63.5391V91.8154H64.7882V89.2705H68.3213V91.8154H70.2323V94.3044H68.3213V98.173C68.3213 98.4061 68.3865 98.5739 68.517 98.6858C68.6475 98.7976 68.834 98.8535 69.0763 98.8535C69.2441 98.8535 69.4492 98.8069 69.6636 98.723C69.8874 98.6391 70.0738 98.5273 70.2323 98.3874V101.212C69.9247 101.38 69.617 101.501 69.3001 101.566C68.9924 101.622 68.6941 101.65 68.3958 101.65Z" fill="#003B4A" />
                    <path d="M76.7956 101.464C75.9007 101.464 75.1083 101.333 74.4092 101.072C73.7193 100.811 73.1693 100.392 72.7685 99.823C72.3676 99.2544 72.1719 98.4993 72.1719 97.5671V91.8154H75.5278V97.362C75.5278 97.8002 75.6303 98.1358 75.8261 98.3688C76.0219 98.5925 76.3575 98.7137 76.8236 98.7137C77.2897 98.7137 77.6253 98.6019 77.821 98.3688C78.0168 98.1451 78.1193 97.8095 78.1193 97.362V91.8154H81.2329V97.5578C81.2329 98.9468 80.832 99.9442 80.0303 100.55C79.21 101.165 78.138 101.464 76.7956 101.464Z" fill="#003B4A" />
                    <path d="M32.9258 98.4898V95.3297H36.086V92.1881V92.1602H32.9258V89H31.2851C30.4461 89 29.7656 89.6805 29.7656 90.5195V92.1602V95.3203V98.4805V100.168C29.7656 100.979 30.4275 101.641 31.2385 101.641H32.9258H36.086H37.7826C38.5936 101.641 39.2554 100.979 39.2554 100.168V98.4805H36.0953H32.9258V98.4898Z" fill="#003B4A" />
                    <path d="M39.2559 95.3296H36.0957V98.4898H39.2559V95.3296Z" fill="#003B4A" />
                    <path d="M38.1465 92.216C38.0254 92.1881 37.9042 92.1694 37.7737 92.1694H36.0957V95.3016V95.3296H39.2559V95.3016V93.7169C39.2559 93.0177 38.7898 92.4118 38.1465 92.216Z" fill="#00C08B" />
                    <path d="M39.7009 175.377C35.715 175.377 32.4825 172.136 32.4825 168.14V153.667C32.4825 139.194 21.655 131.958 21.655 131.958V103.012V95.7751C21.655 91.7792 18.4226 88.5386 14.4367 88.5386H10.8275C8.83344 88.5386 7.21834 90.1577 7.21834 92.1568V103.012V124.721C7.21834 124.721 0 129.35 0 139.194V153.667C0 163.511 7.21834 168.14 7.21834 168.14V182.613V189.85V237H57.7467V196.161C57.7467 186.663 61.3559 187.586 61.3559 187.586C73.8752 187.586 79.4017 175.374 79.4017 175.374H57.7467H39.7009V175.377Z" fill="#F6AEA6" />
                    <path d="M90.2301 124.721H97.4484C101.434 124.721 104.667 121.48 104.667 117.485C104.667 113.489 101.434 110.248 97.4484 110.248H90.2301C86.2442 110.248 83.0117 113.489 83.0117 117.485C83.0117 121.48 86.2442 124.721 90.2301 124.721Z" fill="#F6AEA6" />
                    <path d="M97.4484 128.339H90.2301C86.2442 128.339 83.0117 131.58 83.0117 135.576C83.0117 139.572 86.2442 142.812 90.2301 142.812H97.4484C101.434 142.812 104.667 139.572 104.667 135.576C104.667 131.578 101.434 128.339 97.4484 128.339Z" fill="#F6AEA6" />
                    <path d="M93.839 146.431H86.6207C82.6348 146.431 79.4023 149.671 79.4023 153.667C79.4023 157.663 82.6348 160.904 86.6207 160.904H93.839C97.8249 160.904 101.057 157.663 101.057 153.667C101.057 149.669 97.8249 146.431 93.839 146.431Z" fill="#F6AEA6" />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="111" height="237" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div >
    );
}

export default Responsive(SecCredit);
